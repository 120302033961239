import JsonApi from 'devour-client';
import AuthService from './AuthService';

class ApiClient {
  constructor(token) {
    this.jsonApi = new JsonApi({ apiUrl: '', logger: false });

    this.jsonApi.headers['Authorization'] = 'Bearer ' + token;

    //
    // MODELS
    //

    this.jsonApi.define('competition', {
      name: '',
      teams: {
        jsonApi: 'hasMany',
        type: 'team'
      }
    });

    this.jsonApi.define('user', {
      name: '',
      email: '',
      password: '',
      pending: '',
      time_zone: '',
      unscoped_user_role_organizations_attributes: ''
    });

    this.jsonApi.define('boost', {
      quantity: '',
      owned_by_ref: '',
      owned_by_type: ''
    });

    this.jsonApi.define('official_type', {
      name: '',
      required_official_type: {
        jsonApi: 'hasOne',
        type: 'official_type'
      }
    });

    this.jsonApi.define('team', {
      name: '',
      can_update: '',
      club_attributes: '',
      competition: {
        jsonApi: 'hasOne',
        type: 'competition'
      },
      club: {
        jsonApi: 'hasOne',
        type: 'club'
      }
    });

    const bid_definition = {
      created_by_ref: '',
      owned_by_ref: '',
      owned_by_type: '',
      match_ref: '',
      match_type: '',
      price: '',
      officials_payment_cents: '',
      fee_cancellation_cents: '',
      official_type_ref: '',
      status: '',
      quantity: '',
      competition_ref: '',
      kickoff_time: '',
      local_kickoff_date: '',
      match_description: '',
      team_refs: '',
      additional_users: '',
      only_additional_users: '',
      open_offers_count: '',
      can_update: '',
      can_update_additional_users: '',
      can_create_offer: '',
      can_create_admin_offer: '',
      responsible_for_payment: '',
      offers: {
        jsonApi: 'hasMany',
        type: 'offer'
      },
      winning_offers: {
        jsonApi: 'hasMany',
        type: 'offer'
      },
      related_bids: {
        jsonApi: 'hasMany',
        type: 'bid'
      },
      required_offer: {
        jsonApi: 'hasOne',
        type: 'offer'
      }
    };

    this.jsonApi.define('bid', bid_definition);
    this.jsonApi.define('offer_dependent_bid', bid_definition);

    this.jsonApi.define('match', {
      kickoff_time: '',
      local_kickoff_date: '',
      location: '',
      notes: '',
      can_update: '',
      away_team_attributes: '',
      home_team: {
        jsonApi: 'hasOne',
        type: 'team'
      },
      away_team: {
        jsonApi: 'hasOne',
        type: 'team'
      },
      competition: {
        jsonApi: 'hasOne',
        type: 'competition'
      },
      administrative_users: {
        jsonApi: 'hasMany',
        type: 'user'
      }
    });

    this.jsonApi.define('tournament', {
      kickoff_time: '',
      local_kickoff_date: '',
      end_time: '',
      location: '',
      name: '',
      number_of_matches: '',
      number_of_fields: '',
      notes: '',
      can_update: '',
      home_team: {
        jsonApi: 'hasOne',
        type: 'team'
      },
      competition: {
        jsonApi: 'hasOne',
        type: 'competition'
      },
      administrative_users: {
        jsonApi: 'hasMany',
        type: 'user'
      }
    });

    this.jsonApi.define('offer', {
      created_by_ref: '',
      status: '',
      boosts: '',
      can_cancel: '',
      cancellation_notes: '',
      bid: {
        jsonApi: 'hasOne',
        type: 'bid'
      },
      dependent_bids: {
        jsonApi: 'hasMany',
        type: 'bid'
      },
      offer_group: {
        jsonApi: 'hasOne',
        type: 'offer_group'
      }
    });

    this.jsonApi.define('offer_group', {
      offers: {
        jsonApi: 'hasMany',
        type: 'offer'
      }
    });

    this.jsonApi.define('report', {
      name: '',
      schema: '',
      ui_schema: '',
      requires_responses: '',
      max_responses: '',
      report_responses: {
        jsonApi: 'hasMany',
        type: 'report_response'
      }
    });

    this.jsonApi.define('report_response', {
      match_ref: '',
      bid_ref: '',
      offer_ref: '',
      created_by_ref: '',
      created_by_type: '',
      response: '',
      report: {
        jsonApi: 'hasOne',
        type: 'report'
      }
    });

    this.jsonApi.define('rating', {
      ratable_ref: '',
      ratable_type: '',
      ratable_role: '',
      match_ref: '',
      bid_ref: '',
      offer_ref: '',
      created_by_role: '',
      value: '',
      comments: ''
    });

    this.jsonApi.define('team_rating', {
      rating: '',
      count: '',
      rating_by_role: ''
    });

    this.jsonApi.define('payment_source', {
      stripe_payment_method_ref: '',
      token: '',
      name: '',
      email: '',
      last4: '',
      payment_provider: '',
      payment_type: '',
      owned_by_ref: '',
      owned_by_type: ''
    });

    this.jsonApi.define('payment_destination', {
      code: '',
      owned_by_ref: '',
      owned_by_type: '',
      last4: '',
      payment_provider: ''
    });

    this.jsonApi.define('payment_transaction', {
      stripe_ref: '',
      amount_cents: '',
      match_ref: '',
      bid_ref: '',
      offer_ref: '',
      stripe_type: '',
      processed_at: '',
      subtype: ''
    });

    this.jsonApi.define('invoice', {
      payment_destination: {
        jsonApi: 'hasOne',
        type: 'payment_destination'
      },
      payment_source: {
        jsonApi: 'hasOne',
        type: 'payment_source'
      }
    });

    this.jsonApi.define('invoice_transaction', {
      stripe_type: '',
      amount_cents: '',
      status: '',
      invoice_number: '',
      created_at: '',
      due_on: '',
      invoice: {
        jsonApi: 'hasOne',
        type: 'report'
      }
    });

    this.jsonApi.define('fee_calculation', {
      payment_descriptions: ''
    });

    this.jsonApi.define('tax_form', {
      year: '',
      payment_destination: {
        jsonApi: 'hasOne',
        type: 'payment_destination'
      }
    });

    this.jsonApi.define('referee_society', {
      name: '',
      time_zone: '',
      referee_society: '',
      competitions: {
        jsonApi: 'hasMany',
        type: 'competition'
      },
      referee_users: {
        jsonApi: 'hasMany',
        type: 'user'
      },
      referee_pools: {
        jsonApi: 'hasMany',
        type: 'referee_pool'
      },
      referee_pool_competitions: {
        jsonApi: 'hasMany',
        type: 'referee_pool_competition'
      },
      official_types: {
        jsonApi: 'hasMany',
        type: 'official_type'
      }
    });

    this.jsonApi.define('referee_pool', {
      name: '',
      referee_society: {
        jsonApi: 'hasOne',
        type: 'referee_society'
      },
      users: {
        jsonApi: 'hasMany',
        type: 'user'
      },
      referee_pool_competitions: {
        jsonApi: 'hasMany',
        type: 'referee_pool_competition'
      },
      referee_pool_competitions_attributes: ''
    });

    this.jsonApi.define('referee_pool_competition', {
      competition: {
        jsonApi: 'hasOne',
        type: 'competition'
      },
      official_type: {
        jsonApi: 'hasOne',
        type: 'official_type'
      },
      referee_pool: {
        jsonApi: 'hasOne',
        type: 'referee_pool'
      }
    });

    this.jsonApi.define('role', {
      name: ''
    });

    const ogranization_definition = {
      name: '',
      type: '',
      time_zone: '',
      referee_society: '',
      union: '',
      club: '',
      teams: {
        jsonApi: 'hasMany',
        type: 'team'
      }
    };

    this.jsonApi.define('organization', ogranization_definition);
    this.jsonApi.define('club', ogranization_definition);
    this.jsonApi.define('union', ogranization_definition);

    this.jsonApi.define('user_role_organization', {
      status: '',
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      role: {
        jsonApi: 'hasOne',
        type: 'role'
      },
      organization: {
        jsonApi: 'hasOne',
        type: 'organization'
      }
    });

    this.jsonApi.define('daily_offer', {
      created_by_ref: '',
      start_time: '',
      end_time: '',
      offers: {
        jsonApi: 'hasMany',
        type: 'offer'
      }
    });

    this.jsonApi.define('reserve_price', {
      price_cents: '',
      official_type_ref: '',
      competition_ref: '',
      payment_source_type: ''
    });

    this.jsonApi.define('fee_group', {
      name: '',
      active: '',
      fee_group_members: {
        jsonApi: 'hasMany',
        type: 'fee_group_member'
      },
      fee_group_competitions: {
        jsonApi: 'hasMany',
        type: 'fee_group_competition'
      },
      reserve_prices: {
        jsonApi: 'hasMany',
        type: 'reserve_price'
      }
    });

    this.jsonApi.define('fee_group_member', {
      fee_id: '',
      fee_type: '',
      fee_type_ref: '',
      fee_fixed_cents: '',
      fee_description: '',
      official_type_ref: ''
    });

    this.jsonApi.define('fee_group_competition', {
      competition_ref: '',
      match_refs: '',
      referee_society_ref: '',
      match_type: ''
    });

    this.jsonApi.define('metric', {
      name: '',
      value: '',
      icon: ''
    });

    this.jsonApi.define('suggestion', {
      bid: {
        jsonApi: 'hasOne',
        type: 'reserve_price'
      },
      suggested_by_ref: '',
      suggested_to_ref: '',
      status: ''
    });

    this.jsonApi.define('coaching_report', {
      match_ref: '',
      bid_ref: '',
      offer_ref: '',
      official_ref: '',
      coaching_ref: '',
      response: '',
      coaching_report_configuration: {
        jsonApi: 'hasOne',
        type: 'coaching_report_configuration'
      }
    });

    this.jsonApi.define('coaching_report_configuration', {
      phase: '',
      schema: '',
      ui_schema: ''
    });

    //
    // MIDDLEWARE
    //

    let insertModelNameMiddleware = {
      name: 'insertModelNameMiddleware',
      req: payload => {
        if (payload.req.model == null && payload.req.params && payload.req.params.model) {
          payload.req.model = payload.req.params.model;
        }
        return payload;
      }
    };

    let deleteMetaMiddleware = {
      name: 'deleteMetaOnPost',
      req: payload => {
        if (payload.req.method === 'POST' || payload.req.method === 'PATCH') {
          delete payload.req.data.meta;
        }
        return payload;
      }
    };

    let addDataToIncluded = {
      name: 'addDataToIncluded',
      res: payload => {
        const { included, data } = payload.res.data;
        if (included) {
          payload.res.data.included = included.concat(data);
        } else if (data) {
          payload.res.data.included = [].concat(data);
        }
        return payload;
      }
    };

    this.jsonApi.insertMiddlewareBefore('POST', insertModelNameMiddleware);
    this.jsonApi.insertMiddlewareBefore('POST', deleteMetaMiddleware);
    this.jsonApi.insertMiddlewareBefore('response', addDataToIncluded);
  }

  setAuthorization(token) {
    this.jsonApi.headers['Authorization'] = 'Bearer ' + token;
  }

  handleError(res) {
    if (res.data && res.data.title === 'Unauthorized') {
      authService.logout();
      window.location.reload();
    } else {
      throw res;
    }
  }

  request(url, method, options, data) {
    return this.jsonApi.request(url, method, options, data).catch(res => this.handleError(res));
  }
}

const authService = new AuthService();
const apiClient = new ApiClient(authService.getToken());

export const apiRequest = (...options) => {
  return apiClient.request(...options);
};

export const setApiAuthorization = (...options) => {
  return apiClient.setAuthorization(...options);
};

export const userProfile = () => {
  return authService.getProfile();
};

export const fetchUserProfile = () => {
  return apiRequest(authService.getDomain() + '/me', 'GET').then(response => {
    authService.setProfile(response.data);
    return response.data;
  });
};

export const refreshToken = () => {
  return authService.refreshToken().then(response => {
    setApiAuthorization(authService.getToken());
  });
};
